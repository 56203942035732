import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  private subscription!: Subscription;

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // unsubsribe previous page subscriptions
    this.unsubscribe();

    return new Promise((resolve) => {
      this.subscription = this.authService.$loggedIn.subscribe(
        async (loggedIn) => {
          if (loggedIn === true) {
            if (await this.authService.hasRole(route.data["roles"])) {
              // allowed
              this.unsubscribe();
              resolve(true);
              return;
            } else {
              // wrong role, redirect home
              this.unsubscribe();
              this.router.navigate(["/prihlasit"], {
                queryParams: { returnUrl: state.url },
              });
              resolve(false);
              return;
            }
          } else if (loggedIn === false) {
            // not logged in, redirect home
            this.unsubscribe();
            this.router.navigate(["/prihlasit"], {
              queryParams: { returnUrl: state.url },
            });
            resolve(false);
            return;
          }
          // if loggedIn or user not defined yet, wait for other value
        }
      );
    });
  }

  unsubscribe = () => {
    this.subscription?.unsubscribe();
  };
}
